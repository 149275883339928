@use "./breakpoints" as bp;

$_default_weight: normal;
$_default_style: normal;
$_default_letter-spacing: 0;
$_default_text-decoration: none;
$_default_text-transform: none;
//private
@mixin _typography($name, $default-family) {
  @include bp.breakpoint(md) {
    font-family: var(
      --typog-#{$name}-family,
      var(--typog-default-#{$default-family}-family)
    );
    font-weight: var(--typog-#{$name}-weight, $_default_weight);
    font-style: var(--typog-#{$name}-style, $_default_style);
    font-size: var(--typog-#{$name}-size);
    line-height: var(--typog-#{$name}-line-height);
    letter-spacing: var(
      --typog-#{$name}-letter-spacing,
      $_default_letter-spacing
    );
    text-decoration: var(
      --typog-#{$name}-text-decoration,
      $_default_text-decoration
    );
    text-transform: var(
      --typog-#{$name}-text-transform,
      $_default_text-transform
    );
  }

  font-family: var(
    --typog-sm-#{$name}-family,
    var(--typog-#{$name}-family, var(--typog-default-#{$default-family}-family))
  );
  font-weight: var(
    --typog-sm-#{$name}-weight,
    var(--typog-#{$name}-weight, $_default_weight)
  );
  font-style: var(
    --typog-sm-#{$name}-style,
    var(--typog-#{$name}-style, $_default_style)
  );
  font-size: var(--typog-sm-#{$name}-size, var(--typog-#{$name}-size));
  line-height: var(
    --typog-sm-#{$name}-line-height,
    var(--typog-#{$name}-line-height)
  );
  letter-spacing: var(
    --typog-sm-#{$name}-letter-spacing,
    var(--typog-#{$name}-letter-spacing, $_default_letter-spacing)
  );
  text-decoration: var(
    --typog-sm-#{$name}-text-decoration,
    var(--typog-#{$name}-text-decoration, $_default_text-decoration)
  );
  text-transform: var(
    --typog-sm-#{$name}-text-transform,
    var(--typog-#{$name}-text-transform, $_default_text-transform)
  );
}

@mixin d1() {
  @include _typography('d1', heading);
}

@mixin h1() {
  @include _typography('h1', heading);
}

@mixin h2() {
  @include _typography('h2', heading);
}

@mixin h3() {
  @include _typography('h3', heading);
}

@mixin h4() {
  @include _typography('h4', heading);
}

@mixin h5() {
  @include _typography('h5', heading);
}

@mixin h6() {
  @include _typography('h6', heading);
}

@mixin button() {
  @include _typography('button', body);
}
@mixin body-large-bold() {
  @include _typography('body-large-bold', body);
}

@mixin body-large() {
  @include _typography('body-large', body);
  strong {
    @include body-large-bold();
  }
}

@mixin body-regular() {
  @include _typography('body-regular', body);
}

@mixin body-bold() {
  @include _typography('body-bold', body);
}

@mixin body-link() {
  @include _typography('body-link', body);
  &:hover {
    text-decoration: var(--typog-body-link-hover-text-decoration, none);
  }
}

@mixin body() {
  @include body-regular();
  strong {
    @include body-bold();
  }
  a {
    @include body-link();
  }
}

@mixin caption() {
  @include caption-regular();
  strong {
    @include caption-bold();
  }
  a {
    @include caption-link();
  }
}

@mixin caption-regular() {
  @include _typography('caption-regular', body);
}

@mixin caption-bold() {
  @include _typography('caption-bold', body);
}

@mixin caption-link() {
  @include _typography('caption-link', body);
  &:hover {
    text-decoration: var(--typog-caption-link-hover-text-decoration, none);
  }
}

@mixin menu-link() {
  @include _typography('menu-link', body);
  &:hover {
    text-decoration: var(--typog-menu-link-hover-text-decoration, none);
  }
}

@mixin header-link() {
  @include _typography('header-link', body);
  &:hover {
    text-decoration: var(--typog-header-link-hover-text-decoration, none);
  }
}

@mixin inherit() {
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-decoration: inherit;
  text-decoration: inherit;
}
