/**
  These variables should only be set/used when using "legacy-styles.scss" file

  They are deprecated and will be removed once all consumers have switched to use new typography
 */
@mixin defaults() {
  --font-family: Arial, sans-serif;
  --font-family-thin: 'VAG Rounded LT Pro Thin', var(--font-family);
  --font-family-light: 'VAG Rounded LT Pro Light', var(--font-family);
  --font-family-semibold: 'VAG Rounded LT Pro Bold', var(--font-family);
  --font-family-bold: 'VAG Rounded LT Pro Bold', var(--font-family);
  --font-family-black: 'VAG Rounded LT Pro Black', var(--font-family);

  --font-size-950: 2.6875rem;
  --font-size-900: 1.875rem;
  --font-size-800: 1.5rem;
  --font-size-700: 1.25rem;
  --font-size-650: 1.1875rem;
  --font-size-600: 1.125rem;
  --font-size-550: 1.0625rem;
  --font-size-500: 1rem;
  --font-size-400: 0.875rem;
  --font-size-200: 0.75rem;
  --font-size-100: 0.625rem;
  --font-size-50: 0.5rem;

  --font-size-h1: 1.75rem;
  --font-size-h2: 1.5rem;
  --font-size-h3: 1.3125rem;
  --font-size-h4: 1.1875rem;

  --font-size-h1-desktop: 2.125rem;
  --font-size-h2-desktop: 1.75rem;
  --font-size-h3-desktop: 1.5rem;
  --font-size-h4-desktop: 1.25rem;

  --line-height-base: 22px;
  --line-height-950: 24px;
  --line-height-900: 20px;
  --line-height-800: 18px;
  --line-height-400: 14px;

  --line-height-h1: 34px;
  --line-height-h2: 30px;
  --line-height-h3: 27px;
  --line-height-h4: 25px;

  --line-height-h1-desktop: 34px;
  --line-height-h2-desktop: 30px;
  --line-height-h3-desktop: 27px;
  --line-height-h4-desktop: 25px;

  --letter-spacing-base: normal;
  --letter-spacing-regular: 0.32px;
  --letter-spacing-100: 1px;
  --letter-spacing-80: 0.8px;
  --letter-spacing-60: 0.6px;
  --letter-spacing-40: 0.4px;
  --letter-spacing-50: 0.5px;
  --letter-spacing-30: 0.3px;
  --letter-spacing-20: 0.2px;

  --icon-size: var(--font-size-400);
  --icon-size-xxs: var(--font-size-50);
  --icon-size-xs: var(--font-size-100);
  --icon-size-sm: var(--font-size-400);
  --icon-size-md: var(--font-size-500);
  --icon-size-lg: var(--font-size-800);
  --icon-size-xl: var(--font-size-900);
}
