@use '../abstracts/icons' as i;

$path: '../fonts/' !default;

@font-face {
  font-family: 'icomoon';
  src: url('#{$path}icons.ttf?c3zd62') format('truetype'),
    url('#{$path}icons.woff?c3zd62') format('woff'),
    url('#{$path}icons.svg?c3zd62#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  @include i.base();

  font-size: var(--icon-size);

  &--xxs {
    font-size: var(--icon-size-xxs);
  }

  &--xs {
    font-size: var(--icon-size-xs);
  }

  &--sm {
    font-size: var(--icon-size-sm);
  }

  &--md {
    font-size: var(--icon-size-md);
  }

  &--lg {
    font-size: var(--icon-size-lg);
  }

  &--xl {
    font-size: var(--icon-size-xl);
  }
}

@each $icon in i.$icons {
  .icon-#{$icon}:before {
    @include i.icon($icon);
  }
}

@each $legacy, $icon in i.$legacy_icons {
  .icon-#{$legacy} {
    @extend .icon-#{$icon};
  }
}

//todo: these are more than icons - needs refactoring into reusable component, with separate icons for each path1,2,3 etc.
.icon-unavailable .path1 {
  &:before {
    content: '\e901';
    color: var(--tone-800);
  }
}

.icon-unavailable .path2 {
  &:before {
    content: '\e902';
    margin-left: -1em;
    color: var(--primary-contrast);
  }
}

.icon-unavailable .path3 {
  &:before {
    content: '\e903';
    margin-left: -1em;
    color: var(--primary-contrast);
  }
}

.icon-info .path1 {
  &:before {
    content: '\e904';
    color: var(--tone-800);
  }
}

.icon-info .path2 {
  &:before {
    content: '\e905';
    margin-left: -1em;
    color: var(--primary-contrast);
  }
}

.icon-more-info-fill .path1 {
  &:before {
    content: '\e976';
    color: var(--primary-500);
  }
}

.icon-more-info-fill .path2 {
  &:before {
    content: '\e977';
    margin-left: -1em;
    color: var(--primary-contrast);
  }
}

.icon-more-info-fill .path3 {
  &:before {
    content: '\e978';
    margin-left: -1em;
    color: var(--primary-contrast);
  }
}
