@use "../abstracts/breakpoints" as bp;
@use "../abstracts/typography" as t;
@use "../abstracts/spacing" as s;

// reusable styles only currently used in sales 2.0 - will be refactored as part of atomic design changes
// AVOID USING

/// @deprecated Use for sales only
.form-panel {
  &--border {
    border: var(--border-width) solid var(--panel-border-color);
    background: var(--panel-bg);
    border-radius: var(--border-radius);
    @include s.padding(3);
  }

  &__title {
    @include s.margin-bottom(2);
    @include t.h2();
  }
}

/// @deprecated Use for sales only
.content-box--highlighted {
  padding: 20px;
  @include bp.breakpoint(md) {
    padding: 30px 40px;
  }

  border: var(--border-width) solid var(--section-border-color);
  border-radius: var(--box-border-radius);
  background: var(--section-bg);
  color: var(--section-text);

  &.highlighted {
    border-top: solid var(--card-highlight-width) var(--accent);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
