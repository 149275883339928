@mixin color-palette() {
  /*
    note - no new shades should be added to the color palette - this should be enough
    the allowed values are:
      - primary/secondary/warning/error/success: 200-800 (in 100 increments only)
      - tone: 100-900 where 100/900 are white/black equivalents
        (may not be exactly white or black, but these are the lightest and darkest the tones ever go in the entire application)
   */

  //todo: can we update this default palette to match dg-new-styles?
  --primary-800: #0168b4;
  --primary-700: #0071bb;
  --primary-600: #0675cd;
  --primary-500: #0a93d3;
  --primary-400: #b3dff1;
  --primary-300: #f0f9fc;

  --tone-900: #000000;
  --tone-800: #111111;
  --tone-700: #333333;
  --tone-600: #414141;
  --tone-500: #afafaf;
  --tone-400: #dddddd;
  --tone-300: #dedede;
  --tone-200: #f6f6f6;
  --tone-100: #ffffff;

  --primary-contrast: #ffffff;
  --accent: #663399;
  --warn: #af2c2b;
  --accept: #228415;
  --package-discount: #3b8524;
}

@mixin layout() {
  // Grid
  --container-max-width: 1088px;
  --gutter: 10px;

  //Spacing
  --spacing-unit-sm: 8px;
  --spacing-unit-lg: 16px;
}
@mixin borders() {
  --border-width: 1px;
  --border-radius: 3px;
  --box-border-radius: 3px;
}
@mixin animations() {
  --transition-duration: 0.3s;
  --transition-ease: ease-in-out;
  --transition-curve: cubic-bezier(0.42, 0, 0, 1.07);
  --ease-transition: var(--transition-duration) var(--transition-ease);
  --curve-transition: var(--transition-duration) var(--transition-curve);
}
@mixin icons() {
  --icon-size: 14px;
  --icon-size-xxs: 8px;
  --icon-size-xs: 10px;
  --icon-size-sm: 14px;
  --icon-size-md: 16px;
  --icon-size-lg: 24px;
  --icon-size-xl: 30px;
}
@mixin forms() {
  --input-shadow: 0 0px 3px 0px var(--tone-400);
  --input-padding: 14px;
  --input-error-icon-circle-size: 20px;
  --input-error-icon-size: 8px;

  --max-input-width: auto;

  // typeahead search icon, select down arrow etc.
  --input-trigger-size: 16px;
}
@mixin shadows() {
  --card-shadow: 0px 0px 3px var(--tone-400);
  --card-shadow--hover: 0px 0px 8px var(--tone-500);
}
