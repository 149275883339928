@use './../molecules/buttons' as b;

.btn {
  @include b.base();
  &.btn--primary {
    @include b.primary($disabled-selector: '&.btn--disabled');
  }
  &.btn--secondary {
    @include b.secondary($disabled-selector: '&.btn--disabled');
  }
  &.btn--tertiary {
    @include b.tertiary($disabled-selector: '&.btn--disabled');
  }
}
