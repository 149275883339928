@mixin defaults($legacy-styles: false) {
  //deprecated
  //todo: replace this with page-bg, panel-bg etc.
  --primary-contrast: var(--tone-100);

  //todo: replace usage with --error
  --warn: var(-error-600);
  //todo: replace usage with --success
  --accept: var(--success-600);

  //if you use error/warning for anything other than background/fill you must use the contrast as background
  //dark themes may chose to invert e.g. tick circle could become white with green tick
  --error: var(--error-600);
  --warning: var(--warning-600);
  --success: var(--success-600);
  //use for text color/border etc when above is used for fill
  --error--contrast: var(--tone-100);
  --warning--contrast: var(--tone-100);
  --success--contrast: var(--tone-100);

  //todo: replace with direct usage, or come up with better name for mapping variable
  //used for card highlights
  --accent: var(--secondary-600);

  //todo: this needs a full color palette
  //used for attention grabbing headlines and card highlights
  --attention: #ce3298;

  //previously was used for darker color - now use --page-bg--alt
  //this is the color of the page as a whole
  --page-bg: var(--tone-100);
  //this is an alternate page background color, for where you want alternating sections
  --page-bg--alt: var(--tone-200);

  //header bg
  --header: var(--tone-100);
  //header text & hero component bg
  --header--contrast: var(--text-color);

  //footer bg
  --footer: var(--tone-100);
  //footer text
  --footer--contrast: var(--text-color);

  @if ($legacy-styles) {
    //deprecared vars
    --btn-active: var(--primary-700);
    --btn-inactive: var(--primary-500);
    --btn-hover: var(--primary-700);
    --btn-default: var(--primary-500);
    --copy: var(--tone-600);
    --copy-contrast: var(--primary-contrast);
    --body: var(--primary-contrast);
    --body-contrast: var(--tone-800);
    --content: var(--primary-contrast);
    --content--contrast: var(--tone-600);
    --content-outline: var(--tone-350);
    --placeholder: var(--tone-620);
    --tooltip: var(--tone-300);
    --link: var(--primary-900);
    --link-active: var(--primary-500);
    --border-color: var(--tone-500);
    --border-input-color: var(--tone-700);
    --typeahead-bg-color: var(--tone-300);
    --typeahead-control-icon-color: var(--tone-600);
    --toggle-switch-bg-color: var(--tone-500);
  }

  //e.g. horizontal bars (todo: do we need different ones depending on background?)
  --separator: var(--tone-400);
  --separator--secondary: var(--tone-300);

  // used for tooltip expanded background, form "stop messages" - i.e. Not in good working order
  --form-card-bg: var(--tone-300);

  //panels
  --panel-bg: var(--tone-100);
  --panel-border-color: var(--tone-400);
  //for important panels that you want to standout
  --panel-bg--highlight: var(--tone-300);
  --panel-bg--highlight-subtle: var(--tone-200);
  //information banners and cards
  --info-card-bg: var(--primary-300);
  --info-card-text: var(--text-color);

  --card-highlight-width: 6px;

  //used for whole sections/forms etc
  --section-bg: var(--page-bg);
  --section-text: var(--tone-600);
  --section-border-color: var(--tone-400);

  // payment options cards - cheryl reviewing
  --stand-out-bg: var(--tone-200);
  --stand-out-bg-border: var(--tone-400);

  //used for borders for fields, and interactive cards
  --border-color--interactive: var(--tone-600);

  // Use for:
  // primary & secondary buttons, links, tooltip icons,
  // card highlight (when triggered by primary element),
  // tabs, matching text on typeahead
  --primary-interactive-color: var(--primary-500);
  --primary-interactive-color--hover: var(--primary-700);
  --primary-interactive-color--active: var(--primary-700);
  --primary-interactive-contrast-color: var(--tone-100);
  --primary-interactive-contrast-color--hover: var(--tone-100);
  --primary-interactive-contrast-color--active: var(--tone-100);

  // Use for:
  // tertiary & toggle buttons
  // card highlight (when triggered by secondary element)
  --secondary-interactive-color: var(--secondary-500);
  --secondary-interactive-color--hover: var(--secondary-700);
  --secondary-interactive-color--active: var(--secondary-700);
  --secondary-interactive-contrast-color: var(--tone-100);
  --secondary-interactive-contrast-color--hover: var(--tone-100);
  --secondary-interactive-contrast-color--active: var(--tone-100);

  //all headings (except below)
  --heading-color: var(--text-color);
  //page headings, important card headings
  --heading--highlight-color: var(--primary-800);

  // Use for:
  // Radio fields / Checkboxes, Card border when selected
  // Anything that shows a selected state that is part of user input
  --user-input-selected-color: var(--success-700);
  --user-input-selected-contrast-color: var(--tone-100);

  //main copy, field values
  --text-color: var(--tone-700);
  //field labels, hints
  --text-color--supporting: var(--tone-700); //todo: temporary solution - will review with design
  --text-color--unimportant: var(--tone-500);
  //placeholder, unimportant text
  --text-color--placeholder: var(--tone-600);
  --text-color--highlight: var(--primary-700);

  //used where we need to show a placeholder fill color, for example future steps on progress bar
  --placeholder-bg: var(--tone-400);

  //specific field styles if we need to customise, but try to stick with above vars)
  --input-background-color: var(--primary-contrast);
  --input-trigger-color: var(--tone-600);
  --input-border-color: var(--tone-700);
  --input-border-color--focus: var(--primary-500);
  --input-text-color: var(--tone-700);
  --input-placeholder-color: var(--text-color--placeholder);
  --typeahead-highlight-color: var(--primary-interactive-color);
  //background color of drop down selected value (could also use for select drop downs - what else?)
  --typeahead-bg-color: var(--tone-300);
  --input-border-color--valid: var(--success);
}
