@use '../../abstracts/typography' as t;

.typog-d1 {
  @include t.d1();
}

.typog-h1 {
  @include t.h1();
}

.typog-h2 {
  @include t.h2();
}

.typog-h3 {
  @include t.h3();
}

.typog-h4 {
  @include t.h4();
}

.typog-h5 {
  @include t.h5();
}

.typog-h6 {
  @include t.h6();
}
.typog-button {
  @include t.button();
}

.typog-body-regular {
  @include t.body-regular();
}

.typog-body-large {
  @include t.body-large();
}

.typog-body-large-bold {
  @include t.body-large-bold();
}

.typog-body-bold {
  @include t.body-bold();
}

.typog-body-link {
  @include t.body-link();
}

.typog-body {
  @extend .typog-body-regular;

  strong {
    @extend .typog-body-bold;
  }

  a {
    @extend .typog-body-link;
  }
}

.typog-caption-regular {
  @include t.caption-regular();
}

.typog-caption-bold {
  @include t.caption-bold();
}

.typog-caption-link {
  @include t.caption-link();
}

.typog-caption {
  @extend .typog-caption-regular;

  strong {
    @extend .typog-caption-bold;
  }

  a {
    @extend .typog-caption-link;
  }
}

.typog-menu-link {
  @include t.menu-link();
}

.typog-header-link {
  @include t.header-link();
}
