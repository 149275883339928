$path: '../fonts/' !default;

//Separated styles fonts
@font-face {
  font-family: 'VAG Rounded LT Pro Bold';
  src: url('#{$path}VAGRoundedLTPro-Bold.eot');
  src: url('#{$path}VAGRoundedLTPro-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('#{$path}VAGRoundedLTPro-Bold.woff2') format('woff2'),
    url('#{$path}VAGRoundedLTPro-Bold.woff') format('woff'),
    url('#{$path}VAGRoundedLTPro-Bold.ttf') format('truetype'),
    url('#{$path}VAGRoundedLTPro-Bold.svg#VAGRoundedLTPro-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VAG Rounded LT Pro Thin';
  src: url('#{$path}VAGRoundedLTPro-Thin.eot');
  src: url('#{$path}VAGRoundedLTPro-Thin.eot?#iefix')
      format('embedded-opentype'),
    url('#{$path}VAGRoundedLTPro-Thin.woff2') format('woff2'),
    url('#{$path}VAGRoundedLTPro-Thin.woff') format('woff'),
    url('#{$path}VAGRoundedLTPro-Thin.ttf') format('truetype'),
    url('#{$path}VAGRoundedLTPro-Thin.svg#VAGRoundedLTPro-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VAG Rounded LT Pro Light';
  src: url('#{$path}VAGRoundedLTPro-Light.eot');
  src: url('#{$path}VAGRoundedLTPro-Light.eot?#iefix')
      format('embedded-opentype'),
    url('#{$path}VAGRoundedLTPro-Light.woff2') format('woff2'),
    url('#{$path}VAGRoundedLTPro-Light.woff') format('woff'),
    url('#{$path}VAGRoundedLTPro-Light.ttf') format('truetype'),
    url('#{$path}VAGRoundedLTPro-Light.svg#VAGRoundedLTPro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VAG Rounded LT Pro Black';
  src: url('#{$path}VAGRoundedLTPro-Black.eot');
  src: url('#{$path}VAGRoundedLTPro-Black.eot?#iefix')
      format('embedded-opentype'),
    url('#{$path}VAGRoundedLTPro-Black.woff2') format('woff2'),
    url('#{$path}VAGRoundedLTPro-Black.woff') format('woff'),
    url('#{$path}VAGRoundedLTPro-Black.ttf') format('truetype'),
    url('#{$path}VAGRoundedLTPro-Black.svg#VAGRoundedLTPro-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro SemiBold';
  src: url('#{$path}SourceSansPro-SemiBold.eot');
  src: url('#{$path}SourceSansPro-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('#{$path}SourceSansPro-SemiBold.woff2') format('woff2'),
    url('#{$path}SourceSansPro-SemiBold.woff') format('woff'),
    url('#{$path}SourceSansPro-SemiBold.ttf') format('truetype'),
    url('#{$path}SourceSansPro-SemiBold.svg#SourceSansPro-SemiBold')
      format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro Regular';
  src: url('#{$path}SourceSansPro-Regular.eot');
  src: url('#{$path}SourceSansPro-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('#{$path}SourceSansPro-Regular.woff2') format('woff2'),
    url('#{$path}SourceSansPro-Regular.woff') format('woff'),
    url('#{$path}SourceSansPro-Regular.ttf') format('truetype'),
    url('#{$path}SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans Light';
  src: url('#{$path}MuseoSans-Light.eot');
  src: url('#{$path}MuseoSans-Light.eot?#iefix') format('embedded-opentype'),
    url('#{$path}MuseoSans-Light.woff2') format('woff2'),
    url('#{$path}MuseoSans-Light.woff') format('woff'),
    url('#{$path}MuseoSans-Light.ttf') format('truetype'),
    url('#{$path}MuseoSans-Light.svg#MuseoSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans Regular';
  src: url('#{$path}MuseoSans-Regular.eot');
  src: url('#{$path}MuseoSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{$path}MuseoSans-Regular.woff2') format('woff2'),
    url('#{$path}MuseoSans-Regular.woff') format('woff'),
    url('#{$path}MuseoSans-Regular.ttf') format('truetype'),
    url('#{$path}MuseoSans-Regular.svg#MuseoSans-Regular') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans Bold';
  src: url('#{$path}MuseoSans-Bold.eot');
  src: url('#{$path}MuseoSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$path}MuseoSans-Bold.woff2') format('woff2'),
    url('#{$path}MuseoSans-Bold.woff') format('woff'),
    url('#{$path}MuseoSans-Bold.ttf') format('truetype'),
    url('#{$path}MuseoSans-Bold.svg#MuseoSans-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

//Combined fonts

@font-face {
  font-family: 'VAG Rounded LT Pro';
  src: url('#{$path}VAGRoundedLTPro-Bold.eot');
  src: url('#{$path}VAGRoundedLTPro-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('#{$path}VAGRoundedLTPro-Bold.woff2') format('woff2'),
    url('#{$path}VAGRoundedLTPro-Bold.woff') format('woff'),
    url('#{$path}VAGRoundedLTPro-Bold.ttf') format('truetype'),
    url('#{$path}VAGRoundedLTPro-Bold.svg#VAGRoundedLTPro-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VAG Rounded LT Pro';
  src: url('#{$path}VAGRoundedLTPro-Thin.eot');
  src: url('#{$path}VAGRoundedLTPro-Thin.eot?#iefix')
      format('embedded-opentype'),
    url('#{$path}VAGRoundedLTPro-Thin.woff2') format('woff2'),
    url('#{$path}VAGRoundedLTPro-Thin.woff') format('woff'),
    url('#{$path}VAGRoundedLTPro-Thin.ttf') format('truetype'),
    url('#{$path}VAGRoundedLTPro-Thin.svg#VAGRoundedLTPro-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VAG Rounded LT Pro';
  src: url('#{$path}VAGRoundedLTPro-Light.eot');
  src: url('#{$path}VAGRoundedLTPro-Light.eot?#iefix')
      format('embedded-opentype'),
    url('#{$path}VAGRoundedLTPro-Light.woff2') format('woff2'),
    url('#{$path}VAGRoundedLTPro-Light.woff') format('woff'),
    url('#{$path}VAGRoundedLTPro-Light.ttf') format('truetype'),
    url('#{$path}VAGRoundedLTPro-Light.svg#VAGRoundedLTPro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VAG Rounded LT Pro';
  src: url('#{$path}VAGRoundedLTPro-Black.eot');
  src: url('#{$path}VAGRoundedLTPro-Black.eot?#iefix')
      format('embedded-opentype'),
    url('#{$path}VAGRoundedLTPro-Black.woff2') format('woff2'),
    url('#{$path}VAGRoundedLTPro-Black.woff') format('woff'),
    url('#{$path}VAGRoundedLTPro-Black.ttf') format('truetype'),
    url('#{$path}VAGRoundedLTPro-Black.svg#VAGRoundedLTPro-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('#{$path}MuseoSans-Light.eot');
  src: url('#{$path}MuseoSans-Light.eot?#iefix') format('embedded-opentype'),
    url('#{$path}MuseoSans-Light.woff2') format('woff2'),
    url('#{$path}MuseoSans-Light.woff') format('woff'),
    url('#{$path}MuseoSans-Light.ttf') format('truetype'),
    url('#{$path}MuseoSans-Light.svg#MuseoSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('#{$path}MuseoSans-Regular.eot');
  src: url('#{$path}MuseoSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{$path}MuseoSans-Regular.woff2') format('woff2'),
    url('#{$path}MuseoSans-Regular.woff') format('woff'),
    url('#{$path}MuseoSans-Regular.ttf') format('truetype'),
    url('#{$path}MuseoSans-Regular.svg#MuseoSans-Regular') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('#{$path}MuseoSans-Bold.eot');
  src: url('#{$path}MuseoSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$path}MuseoSans-Bold.woff2') format('woff2'),
    url('#{$path}MuseoSans-Bold.woff') format('woff'),
    url('#{$path}MuseoSans-Bold.ttf') format('truetype'),
    url('#{$path}MuseoSans-Bold.svg#MuseoSans-Bold') format('svg');
  font-weight: 600; //todo: this is just copied from 700 below
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('#{$path}MuseoSans-Bold.eot');
  src: url('#{$path}MuseoSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$path}MuseoSans-Bold.woff2') format('woff2'),
    url('#{$path}MuseoSans-Bold.woff') format('woff'),
    url('#{$path}MuseoSans-Bold.ttf') format('truetype'),
    url('#{$path}MuseoSans-Bold.svg#MuseoSans-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('#{$path}SourceSansPro-SemiBold.eot');
  src: url('#{$path}SourceSansPro-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('#{$path}SourceSansPro-SemiBold.woff2') format('woff2'),
    url('#{$path}SourceSansPro-SemiBold.woff') format('woff'),
    url('#{$path}SourceSansPro-SemiBold.ttf') format('truetype'),
    url('#{$path}SourceSansPro-SemiBold.svg#SourceSansPro-SemiBold')
      format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('#{$path}SourceSansPro-Regular.eot');
  src: url('#{$path}SourceSansPro-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('#{$path}SourceSansPro-Regular.woff2') format('woff2'),
    url('#{$path}SourceSansPro-Regular.woff') format('woff'),
    url('#{$path}SourceSansPro-Regular.ttf') format('truetype'),
    url('#{$path}SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('#{$path}SourceSansPro-Bold.eot');
  src: local(''),
    url('#{$path}SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$path}SourceSansPro-Bold.woff2') format('woff2'),
    url('#{$path}SourceSansPro-Bold.woff') format('woff'),
    url('#{$path}SourceSansPro-Bold.ttf') format('truetype'),
    url('#{$path}SourceSansPro-Bold.svg#SourceSansPro') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('#{$path}/open-sans-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$path}/open-sans-v18-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$path}/open-sans-v18-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('#{$path}/open-sans-v18-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('#{$path}/open-sans-v18-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('#{$path}/open-sans-v18-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('#{$path}/open-sans-v18-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$path}/open-sans-v18-latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$path}/open-sans-v18-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{$path}/open-sans-v18-latin-600.woff')
      format('woff'),
    /* Modern Browsers */ url('#{$path}/open-sans-v18-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('#{$path}/open-sans-v18-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('#{$path}/open-sans-v18-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$path}/open-sans-v18-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$path}/open-sans-v18-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{$path}/open-sans-v18-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('#{$path}/open-sans-v18-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('#{$path}/open-sans-v18-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
