/*
  Note that most new typography variables have default values at present
  this is to maintain flexibility, and preserve compatibility

  in each theme, or within your app, you should include a mixin from
  `base/typography/presets`

  This will either set the variables to:
   - the new styles based on styleguide
   - the old default styles (with no dependency on legacy vars)
   - to map the old vars to the new vars

   Please see storybook for more documentation.

   There are a set of typography styles:
    d1
    h1
    h2
    h3
    h4
    h5
    h6
    body-regular
    body-bold
    body-link
    body-large
    body-large-bold
    button
    caption-regular
    caption-bold
    caption-link
    menu-link
    header-link

   Each style can have variables for large screens (>=md) and small screens

   Each style has variables for:
    family
    weight
    style
    size
    line-height
    letter-spacing
    text-decoration
    text-transform

   If not specified - small screen variables fallback to large screen equivalent

   Font family vars all default to either --typog-default-heading-family (d1 & h1-h6) or --typog-default-body-family

   Other defaults:
    weight: normal
    style: normal
    letter-spacing: 0
    text-decoration: none
    text-transform: none

   Variables are defined as --typog[-sm]-{style}-{property}

   e.g.:

    --typog-h4-family
    --typog-h4-weight
    --typog-h4-style
    --typog-h4-size
    --typog-h4-line-height
    --typog-h4-letter-spacing
    --typog-h4-text-decoration
    --typog-h4-text-transform
    --typog-sm-h4-family
    --typog-sm-h4-weight
    --typog-sm-h4-style
    --typog-sm-h4-size
    --typog-sm-h4-line-height
    --typog-sm-h4-letter-spacing
    --typog-sm-h4-text-decoration
    --typog-sm-h4-text-transform

 */
@mixin defaults() {
  --typog-default-heading-family: Arial, sans-serif;
  --typog-default-body-family: Arial, sans-serif;
}
