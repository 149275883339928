@use "../abstracts/spacing" as s;
@use "../abstracts/typography" as t;
$legacy-styles: true !default;
/************************/
/* UI base form styles  */
/************************/

/**
  .form-group is always the top level div wrapping the component, containing the label, input, tooltip, hint, error etc. (not all e.g. submit)
  .form-control seems to be intended for the actual input, but in some instances is placed on parent elements:
    - month-year-date, year-date, select use on both a parent div and the input
    - submit uses on parent div but not button
  .form-input is only used by radio component

  <div class="form-group">
    <div class="form-group__header">
      <label class="label">
        Label Text
        <dgx-dfb-tooltip></dgx-dfb-tooltip>
      </label>
    </div>
    <ng-container dgxDfbTooltipTarget [tooltipName]="tooltipName"></ng-container>
    <div class="form-group__feedback">
      <input class="form-control" />
      <i class="icon icon-tick-circle icon--xxs"></i>
      <i class="icon icon-error-circle icon--xxs"></i>
    </div>
    <dgx-dfb-error></dgx-dfb-error>
    <div class="form-group__hint">Hint Text</div>
  </div>

  Rendered with populated child components (component elements removed) you get:

  <div class="form-group">
    <div class="form-group__header">
      <label class="label">
        Label Text
        <section class="tooltip">
          <div class="icon icon-more-info-fill tooltip__icon">
            <span class="path1 tooltip__icon-content"></span>
            <span class="path2 tooltip__icon-content"></span>
            <span class="path3 tooltip__icon-content"></span>
          </div>
        </section>
      </label>
    </div>
    <div class="tooltip__content">
      Tooltip content
    </div>
    <div class="form-group__feedback">
      <input class="form-control" />
      <i class="icon icon-tick-circle icon--xxs"></i>
      <i class="icon icon-error-circle icon--xxs"></i>
    </div>
    <span class="form-group__error">Error Message</span>
    <div class="form-group__hint">Hint Text</div>
  </div>

  classes that get added by angular (to component registered as a form control):
    .ng-valid
    .ng-invalid
    .ng-pending
    .ng-pristine
    .ng-dirty
    .ng-untouched
    .ng-touched

  classes that get used in the internals of some components (added to .form-control) :
    .is-invalid
    .is-valid
 */

.form-control, .form-group__feedback, .tooltip__content, .form-group > .select .wrapper  {
  // todo: this is simplified and moved from dg-new-styles, needs considering as a more reusable solution
  // one size does not fit all (number inputs for price etc.)
  max-width: var(--max-input-width, auto);
}

/* form markup */
.form-input,
.form-group {
  @if ($legacy-styles) {
    margin: 10px 0 20px;
  } @else {
    @include s.margin-bottom(2);
  }
  display: flex;
  flex-direction: column;

  .form-control {
    width: 100%;
    position: relative;
    @if ($legacy-styles) {
      margin-bottom: 10px;
    }

    &__hint {
      text-align: left;
      @if ($legacy-styles) {
        size: var(--font-size-500);
        letter-spacing: var(--letter-spacing-regular);
      }
      color: var(--text-color--unimportant);
    }
  }
  &__hint {
    @if not($legacy-styles) {
      @include t.caption-regular();
    }
    color: var(--text-color--unimportant);
  }

  &__error {
    color: var(--error);
    display: flex;
    @if ($legacy-styles) {
      line-height: var(--line-height-base);
    } @else {
      @include s.margin-top(0.5);
      @include t.caption-regular();
    }
  }

  &__hint {
    @if ($legacy-styles) {
      margin-top: 5px;
      margin-bottom: 10px;
      line-height: var(--line-height-base);
    } @else {
      @include s.margin-top(0.5);
    }
  }
}
//forms from form builder
@if not($legacy-styles) {
  dgx-dfb-form {
    form {
      & > .ng-star-inserted:last-child {
        & > .form-control,
        & > .form-group {
          margin-bottom: 0;
        }
      }
    }
  }
}

/* form element styling */
.ui-inputtext,
input:not(.mat-input-element):not(.pure):not([type='radio']),
textarea,
select {
  width: 100%;
  color: var(--text-color);
  background: var(--input-background-color);
  border-radius: var(--input-border-radius, var(--border-radius));
  border: solid var(--border-width) var(--input-border-color);
  @if ($legacy-styles) {
    font-size: var(--font-size-500);
    line-height: 22px;
    padding: 0 12px;
    height: 48px;
  } @else {
    @include t.body-bold();
    padding: var(--input-padding);
  }
  transition: all var(--ease-transition);
  display: block;
  box-shadow: var(--input-shadow);
  &:focus {
    outline-width: 1px;
    outline-color: var(--input-border-color--focus);
  }
}

/* placeholders */
::placeholder {
  opacity: 1; /* Firefox */
}

::placeholder,
.form-input .select--empty,
.form-group .select--empty {
  color: var(--input-placeholder-color);
  @if not($legacy-styles) {
    @include t.body-regular();
  }
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 & Edge */
  color: var(--input-placeholder-color);
}

/* material datepicker */
.form-input .mat-form-field-appearance-outline {
  display: flex;

  .mat-form-field-wrapper {
    margin: 0;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-start,
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border: solid var(--border-width) var(--input-border-color);
  }
  .mat-form-field-outline-start {
    border-radius: var(--border-radius) 0 0 var(--border-radius);
  }
  .mat-form-field-outline-end {
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
  }
  .mat-form-field-outline {
    color: var(--input-border-color);
    &-thick {
      color: var(--primary-500);
    }
  }
  .mat-form-field-infix {
    padding: 10px 0 14px 0;
    margin: 0;
    border-top: 10px solid transparent;
  }
  .mat-datepicker-toggle {
    top: 2px;
    position: relative;
  }
}

/* icon validators */
.form-group {
  label,
  .label {
    display: inline-block;
    color: var(--text-color--supporting);
    @if ($legacy-styles) {
      margin-bottom: 10px;
      font-family: var(--font-family-bold);
      font-weight: bold;
      font-size: var(--font-size-500);
      line-height: 22px;
    } @else {
      @include s.margin-bottom(1);
      @include t.body-large-bold();
    }
  }

  .icon-tick-circle,
  .icon-error-circle {
    width: var(--input-error-icon-circle-size);
    height: var(--input-error-icon-circle-size);
    border-radius: 50%;
    top: calc(-0.5 * var(--input-error-icon-circle-size));
    right: calc(-0.4 * var(--input-error-icon-circle-size));
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform var(--ease-transition);
    transform: scale(0);
    z-index: 2;
  }

  //these dont apply to form builder text input fields because  it is ng-valid and ng-touched that is used
  //todo: what do they apply to?
  &.is-valid {
    input,
    textarea,
    select {
      border: 1px solid var(--input-border-color--valid);
    }
    .icon-tick-circle {
      transform: scale(1);
      background: var(--success);
      color: var(--success--contrast);
      &::before {
        font-size: var(--input-error-icon-size) !important;
      }
    }
  }

  &.is-invalid {
    input,
    textarea,
    select {
      border: var(--border-width) solid var(--error);
    }
    .icon-error-circle {
      transform: scale(1);
      background: var(--error);
      color: var(--error--contrast);
      &::before {
        //todo: update icons so they are consistently sized
        font-size: calc(0.8 * var(--input-error-icon-size));
        font-weight: bold;
      }
    }
  }
}

/* select */
.form-group .select {
  position: relative;
  label {
    display: flex;
  }
  .wrapper {
    display: inline-flex;
    align-items: center;
    position: relative;
    @if ($legacy-styles) {
      margin-bottom: 10px;
    }@else{
      width: 100%;
    }
    .icon-arrow-down {
      pointer-events: none;
      position: relative;
      left: -30px;
    }
  }
  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    select {
      @if ($legacy-styles) {
        width: auto;
      }
      background-color: var(--input-background-color);
      @if ($legacy-styles) {
        padding: 0 55px 0 20px;
      }
      margin-bottom: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      @if($legacy-styles){
        z-index: 2;
        position: relative;
      }
    }
  }
}

/* radio */
.radio {
  label {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    span {
      display: inline-flex;
      margin-left: 10px;
      font-family: var(--font-family);
      font-weight: normal;
    }
  }

  &.horizontal label {
    display: inline-flex;
    margin-right: 35px;
  }

  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type='radio'] {
      --active: var(--primary-contrast);
      --active-inner: var(--primary-500);
      --focus: 2px var(--primary-500);
      --border: var(--tone-600);
      --border-hover: var(--primary-500);
      --border-active: var(--primary-500);
      --background: var(--primary-contrast);
      --disabled: var(--primary-contrast);
      --disabled-inner: var(--tone-500);
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 22px;
      width: 22px;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      cursor: pointer;
      border: var(--border-width) solid var(--border-alt, var(--border));
      background: var(--background-alt, var(--background));
      border-radius: 50%;

      &:after {
        content: '';
        display: flex;
        transition: transform var(--dot-transition, 0.3s)
            var(--dot-transition-ease, ease),
          opacity var(--dot-opacity, 0.2s);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: var(--active-inner-alt, var(--active-inner));
        opacity: var(--opacity-alt, 0);
      }
      &:checked {
        --opacity-alt: 1;
        --transform-scale: 0.5;
        --background-alt: var(--active);
        --border-alt: var(--border-active);
        --dot-opacity: 0.3s;
        --dot-transition: 0.6s;
        --dot-transition-ease: cubic-bezier(0.2, 0.85, 0.32, 1.2);
      }
      &:disabled {
        --background-alt: var(--disabled);
        --border-alt: var(--border);
        --active-inner-alt: var(--tone-600);
        cursor: not-allowed;
        opacity: 0.9;
        & + label {
          cursor: not-allowed;
        }
      }
      &:focus {
        border: solid var(--border-width) var(--primary-500);
      }
    }
  }
}

// todo: overrides should really live within their components
// but without a class on body to enable legacy-styles, we can't turn on/off
@if not($legacy-styles) {
  .form-control,
  .form-group {
    button,
    button.btn {
      margin: 0;
    }
    .tooltip__content[class] {
      @include s.padding(1);
      @include s.margin-bottom(1);
      @include t.body-regular();
      border-top-color: var(--primary-interactive-color);
      border-top-width:var(--card-highlight-width);
    }
  }
  .form-control__submit {
    @include s.margin-top(3);
  }
  .form-group {
    &.yes-no-radio {
      .yes-no-radio__label {
        margin-bottom: 0 !important;
      }
      .yes-no-radio__header {
        @include s.margin-bottom(1);
      }
      .yes-no-radio__options {
        margin-top: 0 !important;
        label {
          margin-bottom: 0 !important;
        }
      }
    }
  }
  dgx-dfb-tooltip[class] {
    display: inline-flex;
    .tooltip {
      vertical-align: middle;
    }
    .tooltip__icon {
      //todo: use vars
      font-size: 16px;
      @include s.padding-x(0);
      @include s.margin-x(0.5);
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: -8px;
        bottom: -8px;
        left: -8px;
        right: -8px;
        border-radius: 16px;
      }
    }
  }
  dgx-dfb-input[class] {
    .form-group input.form-control {
      padding: var(--input-padding);
      height: auto;
    }
  }
  dgx-dfb-select[class] {
    .form-group {
      .select select {
        height: auto;
        @supports (-webkit-appearance: none) or (-moz-appearance: none) {
          padding: var(--input-padding);
          // input-padding once from edge of field, and once to space between text
          padding-right: calc(
            var(--input-padding) + var(--input-trigger-size) +
              var(--input-padding)
          );
        }
        box-shadow: none;
      }
    }
  }
  dgx-dfb-type-ahead[class] {
    .form-group input.form-control {
      height: auto;
      // input-padding once from edge of field, and once to space between text
      padding-right: calc(
        var(--input-padding) + var(--input-trigger-size) + var(--input-padding)
      );
      box-shadow: none;
    }
    .icon-search,
    .icon-close {
      font-size: var(--input-trigger-size);
      top: 50%;
      margin: calc(-0.5 * var(--input-trigger-size)) 0;
      right: var(--input-padding);
    }
    .form-group__result[class] {
      .typeahead-option {
        &[class] {
          @include t.body-regular();
        }
        &--highlight[class] {
          @include t.body-bold();
        }
      }
    }
  }
}
