@use '../base/typography/presets/dg-new-styles' as dgns-typography-preset;
@use '../abstracts/variables/all' as v;
@use '../abstracts/spacing' as s;
@use '../base/palettes/dg-new' as palette-preset;

//legacy variables do not use
.sales {
  // Typography
  --font-family: 'Source Sans Pro', Arial, sans-serif;
  --font-generic-family: var(--font-family);
  --font-family-heading: 'Museo Sans Regular', var(--font-family);
  --font-family-heading-bold: 'Museo Sans Bold', var(--font-family);
  --font-family-heading-light: 'Museo Sans Light', var(--font-family);
  --font-family-extralight: 'Museo Sans Light', var(--font-generic-family);
  --font-family-light: 'Museo Sans Light', var(--font-generic-family);
  --font-family-semibold: 'Source Sans Pro SemiBold', var(--font-generic-family);
  --font-family-bold: 'Museo Sans Bold', var(--font-generic-family);
  --font-family-black: 'Museo Sans Bold', var(--font-generic-family);

  --font-family-label-thin: 'Museo Sans Light', var(--font-generic-family);
  --font-family-label-light: 'Museo Sans Light', var(--font-generic-family);
  --font-family-label-regular: 'Museo Sans Regular', var(--font-generic-family);
  --font-family-label-semibold: 'Museo Sans Bold', var(--font-generic-family);
  --font-family-label-bold: 'Museo Sans Bold', var(--font-generic-family);

  --font-family-icon: 'icomoon';

  --font-size-750: 1.375rem;

  --font-size-base: var(--font-size-500);
  --font-size-label: var(--font-size-600);

  --font-size-h1: 2.125rem;
  --font-size-h2: 1.75rem;
  --font-size-h3: 1.5rem;
  --font-size-h4: 1.25rem;
  --font-size-banner: var(--font-size-700);

  --font-size-h1-desktop: 2.625rem;
  --font-size-h2-desktop: 2rem;
  --font-size-h3-desktop: 1.625rem;
  --font-size-h4-desktop: 1.375rem;
  --font-size-banner-desktop: var(--font-size-750);

  --line-height-base: 24px;

  --line-height-h1: 43px;
  --line-height-h2: 37px;
  --line-height-h3: 32px;

  --line-height-h1-desktop: 55px;
  --line-height-h2-desktop: 42px;
  --line-height-h3-desktop: 34px;

  --letter-spacing-40: 0.4px;
  --letter-spacing-35: 0.35px;
}
//todo: make a mixin so you can reuse better
.sales,.oren {
  @include v.defaults($legacy-styles: false);
  @include palette-preset.set-vars();

  --primary-interactive-color: var(--primary-500);
  --primary-interactive-color--hover: var(--primary-700);
  --primary-interactive-color--active: var(--primary-700);

  --heading--highlight-color: var(--heading-color);

  // Default theme
  --footer: var(--tone-300);
  --footer--contrast: var(--tone-700);

  //buy now button - not part of theme
  --btn-secondary-copy: var(--primary-300);

  //note not part of theme
  --basket-icon: var(--accent);

  --max-input-width: 348px;
  --landscape-column-width: 440px;

  // Buttons
  --btn-height: auto;
  --btn-width: auto;
  --btn-max-width: 100%;
  --btn-max-width-sm: 100%;
  --btn-justify-content: space-between;
  --btn-text-align: left;
  --btn-padding: 16px 30px;
  --btn-icon-spacing: 30px;

  --btn-border-width: 2px;
  --btn-border-radius: var(--border-radius);
  --btn-disabled-shadow: none;
  --btn-disabled-opacity: 0.5;
  --btn-hover-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  --btn-active-scale: 0.95;

  --btn-primary-color: var(--primary-interactive-contrast-color);
  --btn-primary-background-color: var(--primary-interactive-color);
  --btn-primary-border-color: var(--primary-interactive-color);
  --btn-primary-hover-color: var(--primary-interactive-contrast-color--hover);
  --btn-primary-hover-background-color: var(--primary-interactive-color--hover);
  --btn-primary-hover-border-color: var(--primary-interactive-color--hover);

  --btn-secondary-color: var(--tone-700);
  --btn-secondary-background-color: var(--primary-interactive-contrast-color);
  --btn-secondary-border-color: var(--primary-interactive-color);
  --btn-secondary-hover-color: var(--primary-interactive-contrast-color--hover);
  --btn-secondary-hover-background-color: var(
    --primary-interactive-color--hover
  );
  --btn-secondary-hover-border-color: var(--primary-interactive-color--hover);

  --btn-secondary-active-color: var(
    --primary-interactive-contrast-color--active
  );
  --btn-secondary-active-background-color: var(--primary-interactive-color);
  --btn-secondary-active-border-color: var(--primary-interactive-color);

  --btn-primary-disabled-background-color: var(--btn-primary-background-color);
  --btn-secondary-disabled-background-color: var(
    --btn-secondary-background-color
  );
  --btn-tertiary-disabled-background-color: var(
    --btn-tertiary-background-color
  );
  --btn-primary-disabled-border-color: var(--btn-primary-border-color);
  --btn-secondary-disabled-border-color: var(--btn-secondary-border-color);
  --btn-tertiary-disabled-border-color: var(--btn-tertiary-border-color);
  --btn-primary-disabled-color: var(--btn-primary-color);
  --btn-secondary-disabled-color: var(--btn-secondary-color);
  --btn-tertiary-disabled-color: var(--btn-tertiary-color);
  --btn-primary-disabled-hover-background-color: var(
    --btn-primary-hover-background-color
  );
  --btn-secondary-disabled-hover-background-color: var(
    --btn-secondary-hover-background-color
  );
  --btn-tertiary-disabled-hover-background-color: var(
    --btn-tertiary-hover-background-color
  );
  --btn-primary-disabled-hover-border-color: var(
    --btn-primary-hover-border-color
  );
  --btn-secondary-disabled-hover-border-color: var(
    --btn-secondary-hover-border-color
  );
  --btn-tertiary-disabled-hover-border-color: var(
    --btn-tertiary-hover-border-color
  );
  --btn-primary-disabled-hover-color: var(--btn-primary-hover-color);
  --btn-secondary-disabled-hover-color: var(--btn-secondary-hover-color);
  --btn-tertiary-disabled-hover-color: var(--btn-tertiary-hover-color);

  --btn-tertiary-color: var(--primary-interactive-color);
  --btn-tertiary-background-color: var(--primary-interactive-contrast-color);
  --btn-tertiary-border-color: var(--primary-interactive-contrast-color);
  --btn-tertiary-hover-background-color: var(
    --primary-interactive-contrast-color--hover
  );
  --btn-tertiary-hover-border-color: var(
    --primary-interactive-contrast-color--hover
  );
  --btn-tertiary-active-background-color: var(--primary-interactive-color);
  --btn-tertiary-active-border-color: var(--primary-interactive-color);
  --btn-tertiary-text-decoration: underline;
  --btn-tertiary-hover-text-decoration: none;
  --btn-tertiary-active-text-decoration: none;

  // Borders
  --border-color--interactive: var(--tone-600);
  //disable green border on valid fields
  --input-border-color--valid: var(--input-border-color);
  --input-border-color--focus: var(--input-border-color);
  --border-radius: 2px;
  --box-border-radius: 4px;

  // Forms
  --input-padding: 12px;
  --input-shadow: none;

  @include dgns-typography-preset.set-vars();
}
